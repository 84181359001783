/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';


/*
 * Used to show loading view.
 */
import { RouteLoading, useInstitutionById, InstitutionContext } from '@ngt/opms';

import ScreeningLogContext from '../context/ScreeningLogContext';

import { useScreeningLog } from '../hooks/useScreeningLog';
import { initialInstitutionState } from '@ngt/opms/dist/store/modules/data/institution';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IScreeningLogResolverProps {
    id?: number;
    institutionId?: number;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogResolver: React.FunctionComponent<IScreeningLogResolverProps> = ({
    id,
    institutionId,
    resolveBeforeLoad,
    children
}) => {

    let conditionalContexts: React.ReactNode = children;

    // Load ScreeningLog
    const [screeningLog, screeningLogLoadState, screeningLogSaveState, screeningLogPreRegState, screeningLogFailState, screeningLogDeleteState, screeningLogActions] =  useScreeningLog(id, true);

    const screeningLogContext = React.useMemo(() => {
        return {
            screeningLog,
            loadState: screeningLogLoadState,
            saveState: screeningLogSaveState,
            preregState: screeningLogPreRegState,
            failState: screeningLogFailState,            
            deleteState: screeningLogDeleteState,
            actions: screeningLogActions
        };
    }, [screeningLog, screeningLogLoadState, screeningLogSaveState, screeningLogActions]);

    //Load institution
    const [institution, institutionLoadState, institutionActions] = useInstitutionById(institutionId!!, !!institutionId ? true : false);

    const institutionContext = React.useMemo(() => {
        return {
            institution: institution,
            loadState: institutionLoadState,
            actions: institutionActions
        };
    }, [institution, institutionLoadState, institutionActions]);

    return (
        <InstitutionContext.Provider value={institutionContext} >
            <ScreeningLogContext.Provider value={screeningLogContext}>
                {
                    conditionalContexts
                }
            </ScreeningLogContext.Provider>
        </InstitutionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogResolver;