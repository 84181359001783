/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FieldGroupContext from '../../../contexts/form/FieldGroupContext';
import FieldProvider from '../FieldProvider';
import FormLabel from '../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../FormErrorHandler';
import FormErrorDisplay, { ShowErrorFunction } from '../FormErrorDisplay';
import FieldErrorFlag, { ShowErrorFlagFunction } from '../FieldErrorFlag';
import Input, { IInputProps } from '../Input';
import { InputComponentType, OmitInputRender } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitFieldProps<T> = Omit<T, 'inputRender' | 'label'>; 

export interface IFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = any
> extends IFieldStyleProps, IInputProps<TComponent, TValue, TError> {
    name: string;
    label?: React.ReactNode;
    inputLabel?: React.ReactNode;
    simpleLabel?: boolean;
    noErrors?: boolean;
    autoRegister?: boolean;
    showError?: ShowErrorFunction;
    showErrorFlag?: ShowErrorFlagFunction;
}

interface IFieldStyleProps {
    fieldColor?: 'Auto' | 'Inherit' | 'None';
    paddingTop?: boolean | number;
    paddingBottom?: boolean | number;
    isGrouped?: boolean;
    hideLabel?: boolean;
    xs?: GridProps['xs'];
    sm?: GridProps['sm'];
    md?: GridProps['md'];
    lg?: GridProps['lg'];
    xl?: GridProps['xl'];
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme, IFieldStyleProps>(theme => ({
    container: {
        borderBottom: props => props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        borderTop: props => props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        marginTop: props => props.fieldColor === 'Auto' || !props.fieldColor ? -1 : undefined,

        '&:nth-child(even)': {
            background: (props: any) => props.fieldColor === 'Auto' || !props.fieldColor ? theme.palette.grey[100] : undefined
        },

        '&:first-child $grid': {
            paddingTop: (props: any) => props.paddingTop === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        },

        '&:last-child $grid': {
            paddingBottom: (props: any) => props.paddingBottom === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        }
    },
    grid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: props => props.paddingTop === false ?
            theme.spacing(0) :
            props.paddingTop === true ?
                theme.spacing(3) :
                props.paddingTop === undefined && props.isGrouped ?
                    theme.spacing(1.5) :
                    props.paddingTop === undefined ?
                        theme.spacing(3) :
                        theme.spacing(props.paddingTop),
        paddingBottom: props => props.paddingBottom === false ?
            theme.spacing(0) :
            props.paddingBottom === true ?
                theme.spacing(3) :
                props.paddingBottom === undefined && props.isGrouped ?
                    theme.spacing(1.5) :
                    props.paddingBottom === undefined ?
                        theme.spacing(3) :
                        theme.spacing(props.paddingBottom),

        //paddingTop: props => props.paddingTop === false ?
        //    theme.spacing(0) :
        //    props.paddingTop === true ?
        //        theme.spacing(3) :
        //        props.paddingTop === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingTop),

        //paddingBottom: props => props.paddingBottom === false ?
        //    theme.spacing(0) :
        //    props.paddingBottom === true ?
        //        theme.spacing(3) :
        //        props.paddingBottom === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingBottom),
    },
    field: {
        [theme.breakpoints.only('xl')]: {
            paddingTop: (props: any) => (props.xl ?? 3) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('lg')]: {
            paddingTop: (props: any) => (props.lg ?? 3) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: (props: any) => (props.md ?? 4) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: (props: any) => (props.sm ?? 12) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: (props: any) => (props.xs ?? 12) === 12 && props.hideLabel !== true ? theme.spacing(1.5) : ''
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Field = <
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = any
>({
    name,
    label,
    inputLabel,
    hideLabel,
    simpleLabel,
    noErrors,
    fieldColor,
    paddingBottom,
    paddingTop,
    xl,
    lg,
    md,
    sm,
    xs,
    autoRegister,
    showError,
    showErrorFlag,
    ...inputProps
}: IFieldProps<TComponent, TValue, TError> & Partial<OmitFieldProps<React.ComponentProps<TComponent>>>) => {

    const isGrouped = React.useContext(FieldGroupContext);

    const classes = useStyles({
        fieldColor: isGrouped ? 'Inherit' : fieldColor,
        paddingTop,
        paddingBottom,
        isGrouped,
        xl,
        lg,
        md,
        sm,
        xs,
        hideLabel
    });

    return (
        <div
            className={classes.container}
        >
            <FormErrorHandler
                forceNew
            >
                <FieldProvider name={name} autoRegister={autoRegister}>
                    <Grid
                        container
                        className={classes.grid}
                    >
                        {
                            hideLabel !== true && (
                                <Grid
                                    item
                                    xs={(xs ? xs === 'auto' || xs === 12 ? 'auto' : 12 - (xs as number) : 12) as any}
                                    sm={(sm ? sm === 'auto' || sm === 12 ? 'auto' : 12 - (sm as number) : 12) as any}
                                    md={(md ? md === 'auto' || md === 12 ? 'auto' : 12 - (md as number) : 8) as any}
                                    lg={(lg ? lg === 'auto' || lg === 12 ? 'auto' : 12 - (lg as number) : 9) as any}
                                    xl={(xl ? xl === 'auto' || xl === 12 ? 'auto' : 12 - (xl as number) : 9) as any}
                                >
                                    {
                                        label === undefined || isString(label) ?
                                            <FormLabel simpleLabel={simpleLabel} label={label} /> :
                                            label

                                    }
                                </Grid>
                            )
                        }
                        <Grid
                            item
                            xs={xs ?? 12}
                            sm={sm ?? 12}
                            md={md ?? 4}
                            lg={lg ?? 3}
                            xl={xl ?? 3}
                            className={classes.field}
                        >
                            <Input
                                noErrorFlag={noErrors}
                                label={inputLabel}
                                showErrorFlag={showErrorFlag}
                                showErrorFlagExt={!showErrorFlag ? showError : undefined}
                                {...inputProps as any}
                            />
                        </Grid>
                    </Grid>
                </FieldProvider>
                {
                    !noErrors && (
                        <FormErrorDisplay showError={showError} />
                    )
                }
            </FormErrorHandler>
        </div>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Field;
