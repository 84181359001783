/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the invitation collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, IconButton, Tooltip, withStyles } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps,
    IInstitution,
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { InvestigatorsContext, IInvestigator } from './EConsentPage'

import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons/faPaperPlane';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useInvitations } from '../hooks/useInvitations';
import InvitationDialog from './InvitationDialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface IInvitationCollapsibleTableProps extends OmitData<ICollapsibleTableProps<Dtos.Invitation>> {
    data?: Array<Dtos.Invitation>;
    title?: string;
    loading?: boolean;
}

interface IInvitationButtonProps {
    label?: string;
    icon?: IconProp;
    action: () => void;
    tooltipTitle?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    button: {
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    iconButton: {
        padding: theme.spacing(0),
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.EConsentView,
    Permission.EConsentAdminister,
];


const useInvitationsColumns = (
    canAdministerEConsent: boolean,
    showInstitution: boolean,
    institutions: Array<IInstitution>,
    setInvitationDialogMode: React.Dispatch<React.SetStateAction<string|undefined>>,
    setInvitationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setInvitationId: React.Dispatch<React.SetStateAction<number|undefined>>
) => {
    const classes = useStyles();

    const investigatorsContext = React.useContext(InvestigatorsContext);

    const onActionClick = React.useCallback((invitationId?: number, mode?: string) => {
        setInvitationId(invitationId);
        setInvitationDialogMode(mode);
        setInvitationDialogOpen(true);
    }, [setInvitationId, setInvitationDialogMode, setInvitationDialogOpen]);

    const invitationColumns = React.useMemo(() => {
        const actionColumns: Array<Column<Dtos.Invitation>> = [];

        const columns: Array<Column<Dtos.Invitation>> = [];

        columns.push({
            field: 'initials',
            title: 'Initials',
            render: (invitation, type) => (invitation.initials),
        });

        if (canAdministerEConsent) {
            columns.push({
                field: 'emailAddress',
                title: 'Email Address',
                render: (invitation, type) => (invitation.emailAddress),
            });
        }

        columns.push({
            field: 'dateSent',
            title: 'Date Sent',
            render: (invitation, type) => (invitation.dateSent ? DateTime.fromISO(invitation.dateSent).toFormat('dd/MM/yyyy, hh:mm a') : ''),
            width: 80
        });

        if (!!showInstitution) {
            columns.push({
                field: 'institutionCode',
                title: 'Institution',
                render: (invitation, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.code === (invitation.institutionCode))?.name}</div>),
                customSort: (data1, data2, type) => ((institutions.find(i => i.code === (data1.institutionCode)) as IInstitution).name) < ((institutions.find(i => i.code === (data2.institutionCode)) as IInstitution).name) ? -1 : 1,
            });
        }

        columns.push({
            field: 'investigator',
            title: 'Investigator',
            render: (invitation, type) => (<div className={classes.textWrap}>{investigatorsContext.investigators?.find(i => i.username === (invitation.investigator))?.name}</div>),
            customSort: (data1, data2, type) => ((investigatorsContext.investigators.find(i => i.username === (data1.investigator)) as any).name) < ((investigatorsContext.investigators!!.find(i => i.username === (data2.investigator)) as any).name) ? -1 : 1,
        });

        if (canAdministerEConsent) {
            actionColumns.push({
                field: 'id',
                title: 'Actions',
                render: invitation => {
                    return (
                        <>
                            <InvitationButton
                                icon={faPaperPlane}
                                action={() => onActionClick(invitation?.id, "resend")}
                                tooltipTitle={'Resend'}
                            />
                            <InvitationButton
                                icon={faTimes}
                                action={() => onActionClick(invitation?.id, "cancel")}
                                tooltipTitle={'Cancel'}
                            />
                        </>
                    );
                },
                width: 100,
                sorting: false
            });
        }

        return [...columns, ...actionColumns];
    }, [showInstitution, institutions, canAdministerEConsent, classes, onActionClick, investigatorsContext]);

    return invitationColumns;
};

const InvitationButton: React.FunctionComponent<IInvitationButtonProps> = ({
    label,
    icon,
    action,
    tooltipTitle
}) => {
    const classes = useStyles();

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return <>
        {
            !!tooltipTitle && <LightTooltip title={tooltipTitle ?? ''} className={classes.tooltip}>
                {
                    icon != null ?
                        <IconButton
                            color="primary"
                            className={classes.iconButton}
                            size="medium"
                            onClick={action}
                        >
                            <FontAwesomeIcon icon={icon} fixedWidth />
                        </IconButton>
                        :
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="small"
                            onClick={action}
                        >
                            {label}
                        </Button>
                }
            </LightTooltip>
        }

        {
            !tooltipTitle && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
                onClick={action}
            >
                {label}
            </Button>
        }
    </>
};

const defaultInstitutions: Array<IInstitution> = [];
const defaultInvitations: Array<Dtos.Invitation> = [];

interface IRowProps {
    columns: Column<Dtos.Invitation>[];
    data: Dtos.Invitation;
}

const Row: React.FunctionComponent<IRowProps> = ({ columns, data }) => {
    const tableData = React.useMemo(() => {
        return columns.map(x => {
            if (x.render) {
                return x.render(data, 'row');
            }

            if (x.field) {
                return (data as any)[x.field] as any;
            }

            return null;
        });
    }, [data, columns])

    return (
        <tr>
            {
                tableData?.map((x, i) => {
                    return (
                        <td key={i}>
                            {x}
                        </td>
                    );
                })
            }
        </tr>
    );
}

const InvitationCollapsibleTable = ({
    data,
    loading,
    title,
    ...tableProps
}: IInvitationCollapsibleTableProps) => {

    const { institutionCode } = useParams<Record<string, string>>();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.filter(i => i.code).find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [invitations, invitationsLoadState, invitationsActions] = useInvitations(institution?.code, true);

    const [[canViewEConsent, canAdministerEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null);

    const titleToUse = title ?? 'Invitation';

    const { investigator, setInvestigator, investigators, setInvestigators } = React.useContext(InvestigatorsContext);

    const invitationsToUse = React.useMemo(() => {
        if (!invitations && !data) {
            return defaultInvitations;
        }

        return (
            data ??
            invitations?.filter(invitation => invitation.statusId === Dtos.StatusTypeEnum.Waiting &&
                (invitation.investigator == investigator?.username || (!investigator && investigators.find(i => invitation.investigator === i?.username)))
            ) ??
            defaultInvitations
        );
    }, [invitations, data, investigator, investigators]);

    const invitationsLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            invitationsLoadState.state === RequestState.None || invitationsLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, invitationsLoadState, permissionLoadState]);

    const [invitationDialogOpen, setInvitationDialogOpen] = React.useState(false);

    const [invitationDialogMode, setInvitationDialogMode] = React.useState<string | undefined>(undefined);

    const [invitationId, setInvitationId] = React.useState<number | undefined>(undefined);

    const columnsToUse = useInvitationsColumns(
        canAdministerEConsent,
        !institution,
        institutions ?? defaultInstitutions,
        setInvitationDialogMode,
        setInvitationDialogOpen,
        setInvitationId
    );

    const [tableCount, setTableCount] = React.useState(1);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [columnsToUse, invitationsToUse]);

    return (
        <>
            {
                <CollapsibleTable
                    key={tableCount}
                    title={titleToUse}
                    entityName="Invitations"
                    loading={invitationsLoadingToUse}
                    data={invitationsToUse}
                    columns={columnsToUse}
                    {...tableProps}
                />
            }

            {
                !!invitationId && !!invitationDialogMode && <InvitationDialog
                    invitationId={invitationId}
                    open={invitationDialogOpen}
                    setOpen={setInvitationDialogOpen}
                    mode={invitationDialogMode as "resend" | "cancel"}
                />
            }

            {
                //columnsToUse && invitationsToUse && (
                //    <table>
                //        <tbody>
                //            {invitationsToUse.map((x, i) => <Row key={i} data={x} columns={columnsToUse} />)}
                //        </tbody>
                //    </table>
                //)
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationCollapsibleTable;
