import { useContext, useEffect, useMemo, useState } from "react";
import { EventStatesById, FormStatesByIds, IEventState, IEventValidationError, IEventValidationResult, IFormState, IFormValidationError, IFormValidationResult, IPatientValidationError, IValidationResult, PatientGetValidationById } from "../../api/dtos";
import { JsonServiceClient, ResponseStatus } from "@servicestack/client";
import { IRequestState, RequestState } from "@ngt/request-utilities";
import OnlinePatientManagementContext from "../../contexts/OnlinePatientManagementContext";

export interface IPatientSummaryForm extends IFormState {
    totalQuestions?: number;
    answeredQuestions?: number;
    result?: IFormValidationResult['result'];
    errors?: IFormValidationError[];
}

export interface IPatientSummaryEvent extends IEventState {
    totalQuestions?: number;
    answeredQuestions?: number;
    result?: IEventValidationResult['result'];
    errors?: IEventValidationError[];
    forms?: IPatientSummaryForm[];
}

export interface IPatientSummary {
    totalQuestions?: number;
    answeredQuestions?: number;
    result?: IValidationResult['result'];
    errors?: IPatientValidationError[];
    events?: IPatientSummaryEvent[];
}

export interface IPatientSummaryListener {
    (state?: IPatientSummary, loadState?: IRequestState<ResponseStatus>, validateState?: IRequestState<ResponseStatus>): void
}

class PatientSummary {
    summary?: IPatientSummary;
    patientId: number;
    client: JsonServiceClient;
    loadState: IRequestState<ResponseStatus>;
    validateState: IRequestState<ResponseStatus>;
    listeners: IPatientSummaryListener[];

    constructor(serviceStackClient: JsonServiceClient, patientId: number) {
        this.client = serviceStackClient;
        this.patientId = patientId;
        this.summary = undefined;
        this.loadState = {
            state: RequestState.None
        };
        this.validateState = {
            state: RequestState.None
        };
        this.listeners = [];
    };

    public reset = () => {
        this.summary = undefined;
        this.loadState = {
            state: RequestState.None
        };
        this.validateState = {
            state: RequestState.None
        };

        this.notify();
    }

    public load = async () => {
        const firstLoad = this.summary === undefined;

        this.update(
            this.summary, 
            {
                state: RequestState.Pending
            },
            {
                state: RequestState.Pending
            }
        );

        const newState: IPatientSummary = {};
        
        try {
            newState.events = await this.loadEvents(this.patientId);

            const formPromises = newState.events.map(async (event) => {
                try {
                    event.forms = await this.loadForms(this.patientId, event.eventDefinitionId!, event.eventRepeat!);

                    if (this.loadState?.state !== RequestState.Failure) {
                        this.update(
                            newState, 
                            this.loadState,
                            this.validateState
                        );
                    }
                }
                catch (err: any) {
                    this.update(
                        undefined, 
                        { 
                            state: RequestState.Failure, 
                            responseStatus: err?.responseStatus 
                        }, 
                        {
                            state: RequestState.Failure, 
                            responseStatus: err?.responseStatus 
                        }
                    );
                }
            })
    
            await Promise.all(formPromises);
    
            if (this.loadState?.state === RequestState.Failure) {
                return;
            }
            
            this.update(
                newState, 
                {
                    state: RequestState.Success
                },
                {
                    state: RequestState.Pending
                }
            );

            await this.validate();
        }
        catch (err: any) {
            this.update(
                undefined, 
                { 
                    state: RequestState.Failure, 
                    responseStatus: err?.responseStatus 
                }, 
                {
                    state: RequestState.Failure, 
                    responseStatus: err?.responseStatus 
                }
            );

            return;
        }
    }

    private loadEvents = async (patientId: number) => {
        const eventResponse = await this.client.get(new EventStatesById({ 
            id: patientId 
        }));

        return eventResponse.states;
    }

    private loadForms = async (patientId: number, eventDefinitionId: number, eventRepeat: number) => {
        const formResponse = await this.client.get(new FormStatesByIds({ 
            id: patientId,
            eventDefinitionId,
            eventRepeat
        }));

        return formResponse.states;
    }

    private loadValidation = async (patientId: number) => {
        const validationResponse = await this.client.get(new PatientGetValidationById({ 
            id: patientId
        }));

        return validationResponse.validationResult;
    }

    public validate = async () => {
        const newState = {...this.summary};

        this.update(
            newState, 
            {
                state: RequestState.Success
            },
            {
                state: RequestState.Pending
            }
        );

        try {
            const validation = await this.loadValidation(this.patientId);

            newState.answeredQuestions = validation.answeredQuestions;
            newState.totalQuestions = validation.totalQuestions;
            newState.errors = validation.errors;
            newState.result = validation.result;

            if (validation.eventResults) {
                for (let eventResults of validation.eventResults) {
                    const event = newState.events?.find(x => 
                        x.eventDefinitionId === eventResults.eventDefinitionId && 
                        x.eventRepeat === eventResults.eventRepeat
                    );

                    if (!event) {
                        continue;
                    }

                    event.answeredQuestions = eventResults.answeredQuestions;
                    event.totalQuestions = eventResults.totalQuestions;
                    event.errors = eventResults.errors;
                    event.result = eventResults.result;

                    if (eventResults.formResults) {
                        for (let formResults of eventResults.formResults) {
                            const form = event.forms?.find(x => 
                                x.formDefinitionId === formResults.formDefinitionId && 
                                x.formRepeat === formResults.formRepeat
                            );
    
                            if (!form) {
                                continue;
                            }

                            form.answeredQuestions = formResults.answeredQuestions;
                            form.totalQuestions = formResults.totalQuestions;
                            form.errors = formResults.errors;
                            form.result = formResults.result;
                        }
                    }
                }
            }

            this.update(
                newState, 
                {
                    state: RequestState.Success
                },
                {
                    state: RequestState.Success
                }
            );
        }
        catch (err: any) {
            this.update(
                newState, 
                { 
                    state: RequestState.Success
                }, 
                {
                    state: RequestState.Failure, 
                    responseStatus: err?.responseStatus 
                }
            );

            return;
        }
    }

    public update = (summary?: IPatientSummary, loadState?: IRequestState<ResponseStatus>, validateState?: IRequestState<ResponseStatus>) => {
        this.summary = summary;
        this.loadState = loadState ?? {
            state: RequestState.None
        };
        this.validateState = validateState ?? {
            state: RequestState.None
        };

        this.notify();
    }

    private notify = () => {
        for (let listener of this.listeners) {
            listener(this.summary, this.loadState, this.validateState);
        };
    }

    public subscribe = (listener: IPatientSummaryListener) => {
        this.listeners.push(listener);

        return () => {
            if (!this) {
                return;
            }
                
            this.listeners = this.listeners.filter(x => x !== listener);
        };
    }
}


const usePatientSummary = (patientId?: number) => {
    const onlinePatientManagement = useContext(OnlinePatientManagementContext);

    const patientSummary = useMemo(() => {
        if (!patientId) {
            return undefined;
        }

        return new PatientSummary(onlinePatientManagement.serviceStackClient, patientId);
    }, [patientId, onlinePatientManagement.serviceStackClient]);

    const [patientSummaryState, setPatientSummaryState] = useState<IPatientSummary>();
    const [loadState, setLoadState] = useState<IRequestState<ResponseStatus>>();
    const [validationState, setValidationState] = useState<IRequestState<ResponseStatus>>();
    const [load, setLoad] = useState<PatientSummary['load']>();
    const [validate, setValidate] = useState<PatientSummary['validate']>();

    useEffect(() => {
        if (patientSummary) {
            const unsubscribe = patientSummary.subscribe((p, s, v) => {
                setPatientSummaryState(p);
                setLoadState(s);
                setValidationState(v);
            })

            setValidate(validate);
            setLoad(load);

            patientSummary.load();


            return () => {
                unsubscribe();
                setValidate(undefined);
                setLoad(undefined);
            }
        }

        return () => {

        };
    }, [patientSummary, setLoadState, setPatientSummaryState, setValidationState, setValidate, setLoad]);

    return {
        patientSummary: patientSummaryState,
        loadState,
        validationState,
        load,
        validate
    }
}

export default usePatientSummary;