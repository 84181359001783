/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes as deleteIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faEdit as updateIcon } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import {
    ICollapsibleTableProps,
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    TrialContextSelector,
    IFormLabel
} from '@ngt/opms';
import { Column } from 'material-table';
import { Link, useParams, useHistory } from 'react-router-dom';
import { RequestState } from '@ngt/request-utilities';
import { makeStyles, Theme, Button, IconButton, PropTypes, Grid, Tooltip, withStyles } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useScreeningLogs } from '../hooks/useScreeningLogs';
import { ScreeningLog } from '../api/screeningLog';
import { ScreeningLogDialogMode } from './ScreeningLogDialog';
import { ScreeningLogDialog } from '..';
import ScreeningLogResolver from './ScreeningLogResolver';
import { IScreeningLog } from '../api/dtos';
import { grey } from '@material-ui/core/colors';



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface IScreeningLogsCollapsibleTableProps<TScreeningLog extends ScreeningLog = ScreeningLog> extends OmitData<ICollapsibleTableProps<TScreeningLog>> {
    data: TScreeningLog[];
    loading: boolean;
    columns: Array<Column<TScreeningLog>>;
    title?: string;
    entityName?: string;
    onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: number) => void;
    allowDelete?: boolean;
    //formFieldsComponent?: any;
    //formLabels?: IFormLabel[];
    //downloadButtons?: JSX.Element;

}

interface IScreeningLogButtonProps {
    icon: IconProp;
    color?: PropTypes.Color;
    onClickFn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    remove: {
        '& *': {
            color: theme.palette.error.main,
            opacity: '0.9'
        },
        '& *:hover': {
            background: 'none'
        }
    },
    disabledRemove: {
        '& *': {
            color: grey[500],
            fontSize: '1rem',
            margin: theme.spacing(0.06, 0)
        }
    },
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    button: {
        padding: theme.spacing(0),
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsAdminister
];

const ScreeningLogButton: React.FunctionComponent<IScreeningLogButtonProps> = ({
    icon,
    color,
    onClickFn,
    disabled
}) => {
    const classes = useStyles();

    return <>
        <IconButton
            color={color ?? "primary"}
            onClick={!disabled ? onClickFn : undefined}
            size="medium"
            disabled={disabled}
            className={classes.button}
        >
            <FontAwesomeIcon icon={icon} fixedWidth />
        </IconButton>
    </>
};

const useScreeningLogsColumns = <TScreeningLog extends ScreeningLog = ScreeningLog>(
    columns: Array<Column<TScreeningLog>>,
    classes: Record<string, string>,
    onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: number) => void,
    allowDelete: boolean,
    institutionId?: number
) => {

    const [[canDeleteScreeningLog], permissionLoadState, actions] = usePermissionsByIds(permissions, null, null, institutionId, null, true);

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const screeningLogColumns = React.useMemo(() => {
        const actionColumns: Array<Column<TScreeningLog>> = [];

        if (canDeleteScreeningLog && allowDelete) {
            actionColumns.push({
                field: 'id',
                title: '',
                render: screeningLog => {
                    return (
                        <LightTooltip title={!screeningLog?.patientId ? "Delete" : "This screening log can no longer be deleted"}>
                            <div className={!!screeningLog?.patientId ? classes.disabledRemove : classes.remove}>
                                {
                                        <ScreeningLogButton
                                            icon={deleteIcon}
                                            onClickFn={(event) => onRemove(event, screeningLog.id)}
                                            disabled={!!screeningLog?.patientId}
                                        />
                                }
                            </div>
                        </LightTooltip>
                    )
                },
                width: 350,
                sorting: false,
                align: 'center'
            });
        }
        
        return columns.concat(actionColumns);
    }, [columns, classes, onRemove])

    return screeningLogColumns;
};


const ScreeningLogsCollapsibleTable = <TScreeningLog extends ScreeningLog = ScreeningLog> ({
    data,
    loading,
    title,
    entityName,
    columns,
    onRemove,
    allowDelete,
    //formFieldsComponent,
    //formLabels,
    //downloadButtons,
    ...tableProps
}: IScreeningLogsCollapsibleTableProps<TScreeningLog>) => {

    const classes = useStyles();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    /*const [screeningLogs, screeningLogsLoadState, screeningLogsActions] = useScreeningLogs<TScreeningLog>(institution?.id, data === undefined);*/

    //const screeningLogsToUse = React.useMemo(() => {
    //    if (!screeningLogs && !data) {
    //        return [];
    //    }

    //    return (data ?? screeningLogs ?? []);
    //}, [screeningLogs, data]);

    //const screeningLogsLoadingToUse = React.useMemo(() => {
    //    return data === undefined && loading === undefined ?
    //        screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending :
    //        loading ?? false;
    //}, [data, loading, screeningLogsLoadState]);

    const titleToUse = title ?? 'Screening Log';

    const entityNameToUse = entityName ?? 'Screening Log';

    const columnsToUse = useScreeningLogsColumns(columns, classes, onRemove, allowDelete ?? true, institution?.id);

    return (
        <>
            <CollapsibleTable
                title={titleToUse}
                entityName={entityNameToUse}
                loading={loading}
                data={data}
                columns={columnsToUse}
                options={{
                    rowStyle: {
                        overflowWrap: 'break-word'
                    },
                    headerStyle: {
                        overflowWrap: 'break-word'
                    },
                    pageSize: 25,
                    pageSizeOptions: [25]
                }}
                {...tableProps}
            />
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogsCollapsibleTable;

