/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { List, makeStyles, ListItem, Collapse, ListItemIcon, ListItemText } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { faChevronUp } from '@fortawesome/pro-duotone-svg-icons/faChevronUp';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import EventDefinitionsContext from '../../../contexts/configuration/EventDefinitionsContext';

import * as Dtos from '../../../api/dtos';
import PatientSummaryForm from './PatientSummaryForm';
import classNames from 'classnames';
import { IPatientSummaryEvent } from '../../../hooks/utility/usePatientSummary';
import { ResponseStatus } from '@servicestack/client';
import { IRequestState } from '@ngt/request-utilities';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


export interface IPatientSummaryActionProps {
    patient: Dtos.IPatient;
    eventDefinition: Dtos.IEventDefinition;
    event: IPatientSummaryEvent;
}

/**
 * This interface defines the properties for the PatientSummaryEvent component.
 */
export interface IPatientSummaryEventProps {
    patient: Dtos.IPatient;
    event: IPatientSummaryEvent;
    eventActions?: Record<string, React.ComponentType<IPatientSummaryActionProps>>;
    multipleEvents?: boolean;
    loadState?: IRequestState<ResponseStatus>;
    validationState?: IRequestState<ResponseStatus>;
}
/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        marginTop: -1
    },
    item: {
        border: `1px solid ${theme.palette.grey[300]}`
    },
    itemDisabled: {
        background: theme.palette.grey[100]
    },
    forms: {
        paddingLeft: theme.spacing(6)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const PatientSummaryEvent: React.FunctionComponent<IPatientSummaryEventProps> = ({
    patient,
    event,
    eventActions,
    multipleEvents,
    loadState,
    validationState,
    children
}) => {
    const classes = useStyles();

    const { eventDefinitions } = React.useContext(EventDefinitionsContext);

    const eventDefinition = React.useMemo(() => {
        return eventDefinitions?.find(ed => ed.id === event.eventDefinitionId);

    }, [eventDefinitions, event.eventDefinitionId]);

    const [expand, setExpand] = React.useState(event.read);

    const onClick = React.useCallback(() => {
        setExpand(!expand);
    }, [setExpand, expand]);

    const ActionComponent = React.useMemo(() => {
        if (!eventActions || !eventDefinition) {
            return undefined;
        }

        return eventActions[eventDefinition.code];
    }, [eventActions, eventDefinition])


    if (!event.visible) {
        return null;
    }

    return (
        <>
            {
                !multipleEvents && !!eventDefinition && event?.forms?.map((formResults, i) => {
                    return (
                        <PatientSummaryForm
                            key={i}
                            validation={formResults}
                            eventRepeat={event.eventRepeat ?? 1}
                            eventDefinition={eventDefinition}
                            patient={patient}
                            loadState={loadState}
                            validationState={validationState}
                        />
                    );
                })
            }

            {
                multipleEvents &&
                <>
                    <div
                    className={classes.container}
                    >
                        <ListItem
                            button
                            className={classNames(classes.item, { [classes.itemDisabled]: !event.read })}
                            onClick={onClick}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon fixedWidth icon={expand ? faChevronUp : faChevronDown} />
                            </ListItemIcon>
                            <ListItemText primary={<strong>{eventDefinition?.repeating ? `${eventDefinition.name} (${event.eventRepeat})` : eventDefinition?.name}</strong>} />
                        </ListItem>
                        {
                            !!ActionComponent && !!eventDefinition && (
                                <ActionComponent
                                    eventDefinition={eventDefinition}
                                    patient={patient}
                                    event={event}
                                />
                            )
                        }
                    </div>
                    <Collapse
                        in={expand}
                    >
                        <div
                            className={classes.forms}
                        >
                            {
                                !!eventDefinition && event?.forms?.map((formResults, i) => {

                                    return (
                                        <PatientSummaryForm
                                            key={i}
                                            validation={formResults}
                                            eventRepeat={event.eventRepeat ?? 1}
                                            eventDefinition={eventDefinition}
                                            patient={patient}
                                            loadState={loadState}
                                            validationState={validationState}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Collapse>
                </>
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientSummaryEvent;