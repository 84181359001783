

/*
 * ---------------------------------------------------------------------------------
 * Internal Imports
 * ---------------------------------------------------------------------------------
 */

import { WindowManagerMessage } from "./index";


/*
 * ---------------------------------------------------------------------------------
 * Enumerations
 * ---------------------------------------------------------------------------------
 */

/*
 * Init => specify the event where the child window being opened.
 *
 * Update => specifies the event where the state of the data in either the parent
 *           or the child is to be updated.
 * 
 *           I.e., If the parent sends the update event, the file in the child
 *           will be overridden. 
 *           If the the child sends the update, the parent file (original) will
 *           be seen as the new version and therefore overridden. 
 * 
 * Finish => event specifiying the child window is to be terminated
 * 
 * Error => an error occurred in either the child or the parent
 * 
 */

export enum WindowReceivedEvent {
    Init = 1,
    Update = 2,
    Finish = 3,
    Error = 4
}


/*
 * ---------------------------------------------------------------------------------
 * Interfaces / Types
 * ---------------------------------------------------------------------------------
 */

interface IWindowManagerOptions {
    newFileName?: string;
    targetUrl: string;
    hostOrigin?: string;
    instanceId: string;
    windowName: string;
    windowOptions: Record<string, string>;
    onInit?: () => void;
    onUpdate?: (data?: IWindowManagerMessageData<any>) => void;
    onFinish?: (data?: IWindowManagerMessageData<any>) => void;
    onError?: (message?: string) => void;
}

export type IWindowManagerMessageOptions = {
    newFileName?: string;
}

export type IWindowManagerMessageData<T = any> = {
    instanceId: string;
    event: WindowReceivedEvent;
    payload?: T;
    options?: IWindowManagerMessageOptions;
};

//export interface IWindowManagerMessage<T = any> extends MessageEvent<IWindowManagerMessageData<T>> { }

/*
 * ---------------------------------------------------------------------------------
 * Defaults
 * ---------------------------------------------------------------------------------
 */

const WINDOW_ORIGIN = Object.keys(window).indexOf('origin') > -1 ? window['origin'] : window.location.origin

const DEFAULT_WINDOW_NAME = 'BCT-REDACT';

const DEFAULT_INSTANCE_ID = 'BCT-REDACT';

const DEFAULT_WINDOW_OPTIONS = {
    location: 'yes',
    height: '570',
    width: '600',
    scrollbars: 'yes',
    status: 'yes',
    resizable: 'yes'
};

const DEFAULT_WINDOW_MANAGER_OPTIONS: IWindowManagerOptions = {
    targetUrl: "/",
    hostOrigin: WINDOW_ORIGIN,
    instanceId: DEFAULT_INSTANCE_ID,
    windowName: DEFAULT_WINDOW_NAME,
    windowOptions: DEFAULT_WINDOW_OPTIONS,
};

/*
 * ---------------------------------------------------------------------------------
 * Body
 * ---------------------------------------------------------------------------------
 */

var instance: Window | null = null;
var options: IWindowManagerOptions | null = null;
var removeSubscription: () => void;

var handleInitEvent = () => {
    if (options && options.onInit) {
        options.onInit();
    }
}

var handleUpdateEvent = (payload: IWindowManagerMessageData) => {
    if (options && options.onUpdate) {
        options.onUpdate(payload);
    }
}

var handleFinishEvent = (payload: IWindowManagerMessageData) => {
    if (options && options.onFinish) {
        options.onFinish(payload);
    }

    instance = null;
}

var handleErrorEvent = (payload: IWindowManagerMessageData) => {
    if (options && options.onError) {
        options.onError(payload.payload);
    }
}

var getWindowOptions = () => {
    let opt = '';

    for (const key of Object.keys(options && options.windowOptions ? options.windowOptions : {})) {
        if (opt !== '') {
            opt += ','
        }

        opt += `${key}=${options && options.windowOptions[key]}`
    }

    return opt;
}

var handleMessageFromWindow = (message: WindowManagerMessage) => {
    // If message received was a dud
    if (!message ||
        !message.data || 
        !message.data.event ||
        !message.data.instanceId ||
        message.data.instanceId !== (options && options.instanceId ? options.instanceId : undefined)) {
            return;
        }

    // Load event
    if (message.data.event === WindowReceivedEvent.Init) {
        handleInitEvent();
    }

    // Update event
    if (message.data.event === WindowReceivedEvent.Update) {
        handleUpdateEvent(message.data);
    }

    // Finish event
    if (message.data.event === WindowReceivedEvent.Finish) {
        handleFinishEvent(message.data);
    }

    // Error event
    if (message.data.event === WindowReceivedEvent.Error) { 
        handleErrorEvent(message.data);
    }
}

const WindowManager = {
    init: (opts?: Partial<IWindowManagerOptions>) => {
        if (!opts || !opts.targetUrl || opts.targetUrl.length === 0) {
            throw new Error('URL is required');
        }

        if (opts.instanceId !== undefined &&
            !opts.instanceId) {
                throw new Error('Instance ID must be defined');
            }

        if (opts.windowName !== undefined &&
            !opts.windowName) {
                throw new Error('Window name must be defined');
            }
        
        options = {
            ...DEFAULT_WINDOW_MANAGER_OPTIONS,
            ...opts
        }

        instance = null;

        handleMessageFromWindow = handleMessageFromWindow.bind(this);

        window.addEventListener(
            'message',
            handleMessageFromWindow
        );

        removeSubscription = () => window.removeEventListener('message', handleMessageFromWindow);
    },
    open: () => {
        var query: string = "";

        // Append instance Id
        query += `instanceid=${options && options.instanceId}`;

        // Append host origin
        const windowOrigin = options && options.hostOrigin ? 
            options && options.hostOrigin :
            WINDOW_ORIGIN ? WINDOW_ORIGIN : ''

        query += `&origin=${encodeURIComponent(windowOrigin)}`;

        var url = (options && options.targetUrl ? options.targetUrl : '') + "?" + query; 

        instance = open(url, (options && options.windowName ? options.windowName : undefined), getWindowOptions());
    },
    dispose: () => {
        if (removeSubscription) {
            removeSubscription();
        }

        if (instance && !instance.closed) {
            instance.close();
        }
    },
    isOpen: () => {
        return (instance && !instance.closed)
    },
    close: () => {
        if (instance) {
            if (!instance.closed) {
                instance.close();
            }

            instance = null;
        }
    },
    postMessage: <type>(data: type, event: WindowReceivedEvent) => {
        // If the instance is running
        if (instance) {
            // Generate message
            var message: IWindowManagerMessageData<type> = {
                instanceId: (options && options.instanceId ? options.instanceId : ""),
                event,
                payload: data,
                options: {
                    newFileName: options ? options.newFileName : undefined
                }
            }
            // Post to target window
            instance.postMessage(message, (options && options.targetUrl ? options.targetUrl : ""));
        }
    },
    getInstanceId: () => {
        return options && options.instanceId;
    }
}



export default WindowManager;

