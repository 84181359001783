/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Typography } from '@material-ui/core';
import React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typings
 */

import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IProHeader {
    containerClass?: string;
    paddingClass?: string;
    showEventInHeader?: boolean;
    formName?: string;
    eventDefinition?: Dtos.IEventDefinition | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

export const ProHeader: React.FC<IProHeader> = ({
    containerClass,
    paddingClass,
    formName,
    showEventInHeader,
    eventDefinition
}) => {
    return (
        <div
            className={containerClass}
        >
            <Typography
                className={paddingClass}
                variant="h1"
            >
                {formName}
            </Typography>
            {
                showEventInHeader && eventDefinition && (
                    <Typography
                        className={paddingClass}
                        variant="h1"
                    >
                        {
                            eventDefinition.name
                        }
                    </Typography>
                )
            }
        </div>
    );
}