/*
 * --------------------------------------------------------------------------------
 * Enumerations
 * --------------------------------------------------------------------------------
 */

export enum FooterPosition {
    None = 0,
    InSteps = 1,
    OutsideStepsBeforeButtons = 2,
    OutsideStepsAfterButtons = 4,
    All = InSteps | OutsideStepsBeforeButtons | OutsideStepsAfterButtons
}