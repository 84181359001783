/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { List } from '@material-ui/core';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../../api/dtos';
import PatientSummaryEvent, { IPatientSummaryActionProps } from './PatientSummaryEvent';
import PatientContext from '../../../contexts/data/PatientContext';
import PatientValidationContext from '../../../contexts/utility/PatientValidationContext';
import { IPatientSummary } from '../../../hooks/utility/usePatientSummary';
import { ResponseStatus } from '@servicestack/client';
import { IRequestState } from '@ngt/request-utilities';
import { IPatient } from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientSummaryList component.
 */
export interface IPatientSummaryListProps {
    patient?: IPatient;
    patientSummary?: IPatientSummary;
    loadState?: IRequestState<ResponseStatus>;
    validationState?: IRequestState<ResponseStatus>;
    eventActions?: Record<string, React.ComponentType<IPatientSummaryActionProps>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const PatientSummaryList: React.FunctionComponent<IPatientSummaryListProps> = ({
    patientSummary,
    loadState,
    validationState,
    patient,
    eventActions
}) => {
    const multipleEvents = React.useMemo(() => {
        let eventCount = 0;

        eventCount = patientSummary?.events?.length ?? 0;

        return eventCount > 1 ? true : false;
    }, [patientSummary?.events]);

    return (
        <List>
            {
                !!patient && !!patientSummary?.events && patientSummary?.events.map((eventResult, i) => {
                    return (
                        <PatientSummaryEvent
                            key={i}
                            patient={patient}
                            event={eventResult}
                            eventActions={eventActions}
                            multipleEvents={multipleEvents}
                            loadState={loadState}
                            validationState={validationState}
                        />
                    );
                })
            }
        </List>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientSummaryList;