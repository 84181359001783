/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { faPaintRoller } from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';

import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';

import { Button, LinearProgress, Theme, Tooltip, darken, makeStyles } from '@material-ui/core';

//import { WindowManager, WindowReceivedEvent } from '@ngt/redact';

import { v4 as uuidv4 } from 'uuid';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import OnlinePatientManagementContext from '../../../contexts/OnlinePatientManagementContext';
import { IInputRenderProps } from '../../../form/components/Input';
import { WindowManager, WindowReceivedEvent } from '@ngt/redact';
import { AlertTitle } from '@material-ui/lab';
import useSnackbar from '../../../hooks/useSnackbar';
import EventDefinitionContext from '../../../contexts/configuration/EventDefinitionContext';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import PatientStateContext from '../../../contexts/configuration/PatientStateContext';
import PatientStatesContext from '../../../contexts/configuration/PatientStatesContext';
import usePatientByStudyNumber from 'src/hooks/data/usePatientByStudyNumber';
import PatientContext from '../../../contexts/data/PatientContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export const TYPE_DEFAULT = 'DEFAULT';

type FileUploadType = typeof TYPE_DEFAULT;

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type FileUploadsProps = IInputRenderProps<Dtos.IFileUpload, Dtos.IValidationError>

export interface IFileUploadProps extends FileUploadsProps {
    display?: FileUploadType;
    onChange?: (value?: Dtos.IFileUpload) => void;
    existingLink?: (value?: Dtos.IFileUpload) => string;
    existingDownloadLink?: (value?: Dtos.IFileUpload) => string;
    disabled?: boolean;
    disableUpload?: (value?: Dtos.IFileUpload, name?: string) => boolean;
    targetUrl?: string;
    valueId?: number;
    redactable?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    input: {
        display: 'none'
    },
    button: {
        padding: theme.spacing(0),
        cursor: 'pointer',
        height: 32
    },
    label: {
        width: '100%',
        padding: theme.spacing(0.75, 2),
        cursor: 'pointer'
    },
    buttonFailed: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,

        '&:hover': {
            background: darken(theme.palette.error.main, 0.20)
        }
    },
    uploadingButton: {
        cursor: 'pointer',
        padding: theme.spacing(0, 2)
    },
    progress: {
        height: theme.spacing(1.5)
    },
    icon: {
        minWidth: 0,
        padding: theme.spacing(0, 1.25),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: theme.spacing(2),

        '& > *': {
            opacity: 0.9
        }
    },
    container: {
        display: 'flex'
    },
    uploadContainer: {
        flex: '1 1 auto'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const FileUpload: React.FunctionComponent<IFileUploadProps> = ({
    display,
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    onChange,
    disabled,
    existingLink,
    existingDownloadLink,
    disableUpload,
    targetUrl,
    valueId,
    redactable = true,
    ...rest
}) => {

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [failed, setFailed] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [request, setRequest] = React.useState<XMLHttpRequest | null>(null);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const buttonRef = React.useRef<HTMLLabelElement>(null);

    const onUploadKeyPress = React.useCallback((event?: React.KeyboardEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        if (event?.charCode === 32) {
            inputRef.current?.click();
        }
    }, [inputRef.current]);

    const onUploadClick = React.useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        inputRef.current?.click();
    }, [inputRef.current]);


    const onChangeCombined = React.useCallback((value?: Dtos.IFileUpload) => {
        if (onChange) {
            onChange(value);
        }

        onInputChange(value);
    }, [onChange, onInputChange]);

    const [isPdf, setIsPdf] = React.useState<boolean>(false);

    React.useEffect(() => {
        const getFile = async () => {
            if (!value) {
                return;
            }

            const sourceUrl = existingDownloadLink ? existingDownloadLink(value) : `/opms/file/view/${valueId}`;
        }

        getFile();

        if (value && (value.extension === "pdf" || value.extension === ".pdf")) {
            setIsPdf(true);
        }

    }, [value])

    const cancelUpload = React.useCallback(() => {
        request?.abort()
        setRequest(null);
        onChangeCombined(undefined);
        setUploading(false);
        setProgress(0);
        setFailed(false);

        if (value?.upload?.guid) {
            onlinePatientManagement.serviceStackClient.delete(new Dtos.DeleteUpload({ guid: value?.upload?.guid }))
        }
    }, [request, setRequest, setProgress, setUploading, onChangeCombined, value, onlinePatientManagement.serviceStackClient])

    const processUploadResponse = React.useCallback((responseStr: string) => {
        try {
            const result: Dtos.UploadResponse = JSON.parse(responseStr);

            if (!result.responseStatus && result.upload) {

                onChangeCombined({ ...value, upload: result.upload } as any);
                setUploading(false);
                setProgress(0);
                setRequest(null);
                setFailed(false);
            }
            else {
                setUploading(false);
                setProgress(0);
                setRequest(null);
                setFailed(true);
            }
        }
        catch {
            setUploading(false);
            setProgress(0);
            setRequest(null);
            setFailed(true);
        }
    }, [setUploading, setFailed, setProgress, setRequest, onChangeCombined, value])

    const uploadFile = React.useCallback((file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();

        if (xhr.upload) {
            xhr.upload.onprogress = (event) => {
                const done = (event as any).position || event.loaded;
                const total = (event as any).totalSize || event.total;

                const percentage = (Math.floor(done / total * 1000) / 10);

                setProgress(percentage);

                //console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done / total * 1000) / 10) + '%');
            };
        }

        xhr.onreadystatechange = (event) => {
            if (4 === xhr.readyState) {
                if (xhr.status === 200) {
                    processUploadResponse(xhr.responseText);
                }
                else if (xhr.status !== 0) {
                    setUploading(false);
                    setFailed(true);
                    setProgress(0);
                }
            }
        };

        xhr.open('post', '/opms/file-upload?format=json', true);

        xhr.send(formData);

        setRequest(xhr);
    }, [onChangeCombined, setProgress, setFailed, setRequest, setUploading, processUploadResponse]);

    const onFileChange = React.useCallback((event?: React.ChangeEvent<HTMLInputElement>) => {
        cancelUpload();

        if (event?.target.value && event?.target.value !== '') {
            const file = event.target.files?.item(0);

            if (file) {
                setUploading(true);
                setProgress(0);
                onChangeCombined(undefined);
                setFailed(false);

                uploadFile(file)
                return;
            }
        }

        setUploading(false);
        setProgress(0);
        onChangeCombined(undefined);
        setFailed(false);
    }, [setUploading, onChangeCombined, setProgress, cancelUpload, setFailed, uploadFile])

    const onFailedClick = React.useCallback(() => {
        setFailed(false);
    }, [setFailed]);

    const handleRedactClick = React.useCallback((sourceUrl: string, fileName: string, fileType: string) => {
        // BCTTUG-93. If the form is disabled (participant is randomised) then do not redact
        if (disabled) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Redaction Denied
                    </AlertTitle>
                    Please contact the trial team for further action on redacting this document.
                </>,
                { variant: 'ineligible' }
            );

            return;
        }

        const percentageWidth = 0.5;
        const percentageHeight = 0.9;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const screenWidth = window.innerWidth ? window.innerWidth : window.screen.width;
        const screenHeight = window.innerHeight ? window.innerHeight : window.screen.height;

        const width = screenWidth * percentageWidth;
        const height = screenHeight * percentageHeight;

        const systemZoom = screenWidth / window.screen.availWidth;
        const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft
        const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop

        let manager = WindowManager;

        manager.init({
            targetUrl: targetUrl,
            hostOrigin: window.location.origin,
            instanceId: uuidv4(),
            windowName: 'redaction_window',
            windowOptions: {
                width: (width / systemZoom).toString(),
                height: (height / systemZoom).toString(),
                top: top.toString(),
                left: left.toString()
            },
            onInit: async () => {
                try {
                    const blob = await fetch(`${window.location.origin}${sourceUrl}`).then(res => res.blob());

                    let fileObj: File;

                    if (fileType === "pdf" || fileType === ".pdf") {
                        if (!fileName.endsWith(".pdf")) {
                            fileName += ".pdf"
                        }
                        fileObj = new File([blob], fileName, { type: "application/pdf" });

                    } else {
                        fileObj = new File([blob], fileName, { type: "" });
                    }


                    if (!fileObj || !(fileObj instanceof File)) {
                        throw new Error('Failed to retrieve file');
                    }

                    manager.postMessage<File>(fileObj, WindowReceivedEvent.Update);
                }
                catch (error: any) {
                    manager.close();
                }
            },
            onUpdate: async (data) => {
                try {
                    if (!!!data) {
                        throw new Error('File failed to redact');
                    }

                    setUploading(true);
                    setProgress(0);
                    onChangeCombined(undefined);
                    setFailed(false);

                    uploadFile(data.payload);

                    setUploading(false);
                    setProgress(0);
                    onChangeCombined(undefined);
                    setFailed(false);

                    manager.close();

                } catch (error: any) {
                    manager.close();
                }
            },
            onFinish: () => {
                manager.close();
            },
            onError: (message) => {
                manager.close()
            }
        });

        manager.open();

    }, [value, targetUrl, disabled])

    const uploadDisabled = React.useMemo(() => {
        if (disableUpload != null) {
            return disableUpload(value as Dtos.IFileUpload, name) || disabled;
        }

        return disabled;
    }, [disabled, disableUpload, value, name]);

    if (uploading) {
        return (
            <>
                <Button
                    variant="contained"
                    component="button"
                    className={classes.uploadingButton}
                    fullWidth
                    color="primary"
                    onClick={cancelUpload}
                >
                    Cancel
                </Button>
                <LinearProgress
                    className={classes.progress}
                    value={progress}
                    color="secondary"
                    variant="determinate"
                />
            </>
        );
    }

    if (failed) {
        return (
            <Button
                variant="contained"
                component="button"
                fullWidth
                className={classes.buttonFailed}
                color="inherit"
                onClick={onFailedClick}
            >
                Upload Failed
            </Button>
        );
    }

    if (value?.upload) {
        return (
            <div
                className={classes.container}
            >
                <div
                    className={classes.uploadContainer}
                >
                    <Button
                        variant="contained"
                        component="button"
                        fullWidth
                        color="primary"
                        onClick={cancelUpload}
                    >
                        Remove Upload
                    </Button>
                </div>
                {
                    value.upload.image && (
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icon}
                            component="a"
                            href={`/opms/file-upload/${value.upload.guid}`}
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                        </Button>
                    )
                }
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.icon}
                    component="a"
                    href={`/opms/file-upload/download/${value.upload.guid}`}
                >
                    <FontAwesomeIcon icon={faDownload} fixedWidth />
                </Button>
                {
                    value.upload.extension === "pdf" && redactable &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.icon}
                        onClick={() => handleRedactClick(`/opms/file-upload/download/${value.upload.guid}`, value.upload.name, value.upload.extension)}
                    >
                        <FontAwesomeIcon icon={faPaintRoller} fixedWidth />
                    </Button>
                }
            </div>
        );
    }

    return (
        <div
            className={classes.container}
        >
            <div
                className={classes.uploadContainer}
            >
                <input
                    ref={inputRef}
                    name={name}
                    id={name}
                    className={classes.input}
                    type="file"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onFileChange}
                    disabled={disabled}
                />
                <Button
                    variant="contained"
                    className={classes.button}
                    component="button"
                    fullWidth
                    color="primary"
                    disabled={uploadDisabled}
                    onKeyPress={onUploadKeyPress}
                    onClick={onUploadClick}
                >
                    <label
                        htmlFor={name}
                        className={classes.label}
                    >
                        {
                            value?.exists ?
                                'Upload New' :
                                'Upload'
                        }
                    </label>
                </Button>
            </div>
            {
                !!value?.exists && !!value?.image && !!existingLink && (
                    <Tooltip title="View">
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icon}
                            component="a"
                            href={existingLink(value)}
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                        </Button>
                    </Tooltip>
                )
            }
            {
                !!value?.exists && !!existingDownloadLink && (
                    <Tooltip title="Download">
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icon}
                            component="a"
                            href={existingDownloadLink(value)}
                        >
                            <FontAwesomeIcon icon={faDownload} fixedWidth />
                        </Button>
                    </Tooltip>
                )
            }
            {
                !!value?.exists && !!existingDownloadLink && isPdf && redactable && (
                    <Tooltip title="Redact">
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icon}
                            onClick={() => handleRedactClick(existingDownloadLink(value), "", value.extension)}
                        >
                            <FontAwesomeIcon icon={faPaintRoller} fixedWidth />
                        </Button>
                    </Tooltip>
                )
            }
        </div>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FileUpload;
